@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");
/*
================ TABLE OF CONTENT =========================
  1 - GLOBAL STYLE
1.1 - COMMON STYLE
1.2 - NAV BAR
1.3 - CAROUSEL
1.4 - FOOTER

2 - HOME PAGE
3 - CAR PAGE
4 - CAR CLAIM PAGE
5 - BIKE PAGE
6 - BIKE CLAIM PAGE
7 - CONTACT US PAGE


================ TABLE OF CONTENT =========================
*/

/* ====== 1 - GLOBAL STYLE STARTS ====== */

body {
  background: #ece9d9 !important;
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}

/* ====== 1.1 - COMMON STYLE STARTS ====== */

p {
  font-size: 15px;
  color: #333333;
  line-height: 1.7rem;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
}
h1 {
  font-size: 2.75rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75rem;
}
h5 {
  font-size: 1.5rem;
}
h6 {
  font-size: 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222222;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

h1,
h2 {
  padding-bottom: 20px;
}

h3,
h4 {
  padding-bottom: 15px;
}

h5,
h6 {
  padding-bottom: 10px;
}
li {
  font-size: 14px;
  color: #4c4c4c;
  list-style: none;
  padding: 0;
  margin: 0;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}
a {
  color: #333333;
  text-decoration: none;
}

a:hover {
  color: #333333;
  text-decoration: none;
}
.text-pink,
.text-pink p,
.text-pink h1,
.text-pink h2,
.text-pink h3,
.text-pink h4,
.text-pink h5,
.text-pink h6 {
  color: #1e5d87;
}

.text-yellow,
.text-yellow p,
.text-yellow h1,
.text-yellow h2,
.text-yellow h3,
.text-yellow h4,
.text-yellow h5,
.text-yellow h6 {
  color: #fdb465;
}

.text-black p,
.text-black h1,
.text-black h2,
.text-black h3,
.text-black h4,
.text-black h5,
.text-black h6 {
  color: #222222;
}

span.text-black {
  color: #222222;
}

.bg-color-dark p,
.bg-color-dark h1,
.bg-color-dark h2,
.bg-color-dark h3,
.bg-color-dark h4,
.bg-color-dark h5,
.bg-color-dark h6,
.bg-color-grad p,
.bg-color-grad h1,
.bg-color-grad h2,
.bg-color-grad h3,
.bg-color-grad h4,
.bg-color-grad h5,
.bg-color-grad h6 {
  color: #ffffff;
}

.bg-color-dark .sub-heading-2,
.bg-color-grad .sub-heading-2 {
  color: #2c1a26;
  margin: 20px 0;
  padding: 0px 0px 0px 20px;
  border-left: 2px solid #2c1a26;
  font-weight: 500;
  line-height: 30px;
  display: block;
}

.sub-heading-1,
.sub-heading-2 {
  color: #1e5d87;
  font-size: 22px;
}
.bg-color-dark .sub-heading-1,
.bg-color-grad .sub-heading-1 {
  color: #2c1a26;
  font-size: 22px;
}
.footer-sec li a {
  text-decoration: none;
}
.sub-heading-1 {
  margin: 0;
  padding: 0px;
  font-weight: 600;
  line-height: 30px;
  display: block;
}
.sub-heading-2 {
  margin: 20px 0;
  padding: 0px 0px 0px 20px;
  border-left: 2px solid #1e5d87;
  font-weight: 500;
  line-height: 30px;
  display: block;
}

.btn {
  color: #f4f4f4;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 15px 20px !important;
  position: relative;
  z-index: 1;
}

.btn:hover {
  color: #ffffff;
}

.btn-prim {
  background-color: #333;
  border: 0px;
  border-radius: 0px;
  padding: 10px;
  display: inline-block;
  line-height: 1rem;
}
.btn-prim-round {
  background-color: #333;
  border: 0px;
  border-radius: 60px;
  padding: 10px;
  display: inline-block;
  line-height: 1rem;
}
.btn-prim-round:hover {
  background-color: #111111;
}
.btn-ter-round {
  color: #333333;
  background-color: transparent;
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 60px;
  padding: 10px;
  display: inline-block;
  line-height: 1rem;
}
.btn-ter-round:hover {
  color: #333333;
  background-color: #d7d2c4;
}

.btn-ter-light {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  padding: 10px;
  display: inline-block;
  line-height: 1rem;
}

.btn-ter-light:hover {
  border: 1px solid rgba(255, 255, 255, 0);
}

.btn-ter-dark {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  padding: 10px;
  display: inline-block;
  line-height: 1rem;
  margin-top: 30px;
  color: #333333;
}

.btn-link {
  background-color: transparent;
  color: #1e5d87;
  border: 0px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.btn-link:hover {
  color: #333333;
  text-decoration: none !important;
}

.btn-link-dark {
  line-height: 1rem;
  margin-top: 30px;
  color: #3182d2;
  transition: all 0.2s ease-in-out;
}

/* Accordion custome style */
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fffdf2;
  background-clip: border-box;
  border: 0px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.card-body {
  background-color: #e5e0cf;
}

.card-body .btn-link {
  display: block;
  margin-top: 20px;
  padding: 0;
}
.card-header:first-child {
  border-radius: 10px;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-radius: 10px;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 10px;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 10px !important;
}
.accordion > .card:last-of-type {
  border-radius: 10px !important;
}
.accordion > .card .card-header {
  padding: 0px;
  margin-bottom: 0px;
  background-color: #fffdf2;
  border: 0px !important;
  border-radius: 10px !important;
  transition: all 0.3s ease-in-out;
}

.accordion > .card .card-header:hover {
  background-color: #1e5d87;
}
.accordion > .card .card-header:hover h2 button {
  color: #ffffff;
}
.card-header h2 {
  padding-bottom: 0px;
  transition: all 0.3s ease-in-out;
}
.card-header h2:hover {
  padding-left: 10px;
}
.card-header h2::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
}
.card-header h2 button {
  color: #1e5d87;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  text-align: left;
  margin-top: 0;
}

.card-header h2 button:visited {
  text-decoration: none;
}

/* Accordion custome style */

.vertical-cntr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-icon-bg-cntr {
  width: 100%;
  background-color: #f2efe1;
  padding: 50px 30px;
  border-radius: 15px;
  position: relative;
  transition: all 0.4s ease-in-out;
}

.col-icon-bg-cntr:hover {
  background-color: #ffffff;
  padding: 50px 30px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}
.col-text-cntr p {
  opacity: 0;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  transition: all 0.4s ease-in-out;
}
.col-icon-bg-cntr:hover .col-text-cntr p {
  opacity: 1;
  display: inline-block;
  top: 30px;
  transform: translateY(-20px);
}

.col-text-cntr {
  text-align: center;
  position: relative;
  transition: all 0.4s ease-in-out;
}

.col-icon-bg-cntr:hover .icon-cntr span::before {
  font-size: 42px;
}

.col-icon-bg-cntr h6 {
  transition: all 0.4s ease-in-out;
}
.col-icon-bg-cntr:hover h6 {
  padding-bottom: 50px;
  transform: translateY(-20px);
}

.col-icon-bg-cntr:hover .icon-cntr {
  padding-bottom: 20px;
  transform: translateY(-20px);
}

.icon-cntr {
  text-align: center;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
}

.icon-cntr span::before {
  font-size: 70px;
  font-weight: 500;
  line-height: normal;
  display: inline;
  margin: 0;
  background: -webkit-linear-gradient(#0ae376, #1e5d87);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
}

.col-icon-bg-cntr .icon-cntr {
  padding-bottom: 30px;
}

.img-container {
  padding: 0px 0px 0px 0px;
}
.img-container img {
  max-width: 100%;
  height: auto;
  position: relative;
}

.text-container-right {
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text-container-left {
  padding-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container-text {
  max-width: 100%;
}

.title-center {
  text-align: center;
  padding-bottom: 60px;
}
.title-center h2 {
  padding-bottom: 20px;
}

.bullet-points ul {
  padding: 10px 0px;
}

.bullet-points ul li {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding: 10px 0px;
  position: relative;
  left: 15px;
  display: block;
}

.bullet-points ul li::before {
  content: "";
  position: absolute;
  display: block;
  left: -15px;
  width: 7px;
  height: 7px;
  background-color: #1e5d87;
  border-radius: 50%;
  top: 18px;
}
/* ================================================== */

.section-first {
  padding: 120px 0px 60px 0px;
}
.section {
  padding: 60px 0px;
}
.section-last {
  padding: 60px 0px 120px 0px;
}

/* ================================================== */

.section-large-first {
  padding: 90px 0px 45px 0px;
}
.section-large {
  padding: 90px 0px;
}
.section-large-last {
  padding: 45px 0px 90px 0px;
}

/* ================================================== */

.section-larger-first {
  padding: 120px 0px 60px 0px;
}
.section-larger {
  padding: 120px 0px;
}
.section-larger-last {
  padding: 60px 0px 120px 0px;
}
/* ================================================== */

.section-padding-none {
  padding: 0px;
}
.ml-15 {
  margin-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-90 {
  margin-top: 90px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mt-negative-20 {
  margin-top: -20px;
}
.mt-negative-30 {
  margin-top: -30px;
}
.mb-negative-30 {
  margin-bottom: -30px;
}
.mt-negative-60 {
  margin-top: -60px;
}
.mb-negative-60 {
  margin-bottom: -60px;
}
.mt-negative-90 {
  margin-top: -90px;
}
.mb-negative-90 {
  margin-bottom: -90px;
}

.bg-color {
  background-color: #f2efe1;
}

.bg-color-pink {
  background-color: #f93156;
}

.bg-color-2 {
  background-color: #d7d2c4;
}
.bg-color-dark {
  background: -moz-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
  background: -webkit-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
  background: url("../public/images/grad_bg.png") no-repeat -200px bottom,
    linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
  /* background: linear-gradient(-120deg, #e86f4b 0%, #f93156 100%); */
  background-size: contain;
}
.bg-color-grad {
  background: -moz-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
  background: -webkit-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
  background: linear-gradient(-120deg, #e86f4b 30%, #f93156 100%);
}

.bg-color-10 {
  background-color: #f2efe1;
  padding-left: 90px;
  padding-right: 90px;
}
.bg-img-left {
  background: url(../public/images/bg_shape_1.png) no-repeat 200% -90px;
  background-size: contain;
}

.bg-img-left-2 {
  background: url(../public/images/bg_shape_3.png) no-repeat bottom left;
  background-size: contain;
}
.bg-img-right {
  background: url(../public/images/bg_shape_2.png) no-repeat -5% -445px;
  background-size: 55%;
}
.bg-img-right-half {
  background: #f2efe1 url(../public/images/city_bg.png) no-repeat center center;
  background-size: cover;
}
.full-bg-color {
  background: #f2efe1 url(../public/images/full_bg.png) no-repeat center center;
  background-size: cover;
}
.full-bg-img {
  background: #f2efe1 url(../public/images/full_bg.png) no-repeat center right;
  background-size: cover;
}

/* ====== 1.2 - NAV BAR STARTS ====== */

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 5px;
}

.navbar-brand img {
  width: auto;
  max-height: 25px;
  padding: 0px 0px;
  margin-top: 12px;
}

.bg-darks {
  width: 100%;
  background-color: #fffdf2 !important;
  padding: 0px 30px;
  border-radius: 15px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out;
  display: flex;
  position: relative;
}
.navbar-light .navbar-nav .nav-link {
  font-size: 15px;
  color: #222222;
  transition: all 0.3s ease-in-out;
  padding: 20px 15px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #26cd79 !important;
  text-decoration: none;
}
.active {
  color: #26cd79 !important;
}

.navbar-light .navbar-nav .active .nav-link {
  color: #1e5d87 !important;
}

.bg-dark.scrolled {
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  background-color: #ffffff !important;
}

.bg-dark.scrolled .nav-link {
  color: #111111;
  font-size: 15px;
}

/* ====== NAV BAR DROPDOWN ====== */
.navbar-expand-lg .navbar-nav .dropdown-menu {
  margin-top: 0px;
  background: #fffdf2;
  border: none;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  padding: 0px;
  transition: all 0.1s ease-in-out;
  border-top: 3px solid #1e5d87;
}

.navbar-expand-lg .navbar-nav .dropdown-menu.dropdownscrolled {
  margin-top: 0px;
}

.dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 15px 10px;
  clear: both;
  font-weight: 400;
  color: #666666;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  box-shadow: none;
}

.dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  white-space: nowrap;
  background-color: #1e5d87;
}

.dropdown-menu .dropdown-item::before {
  content: "-";
  font-weight: bold;
  color: #ffffff;
  padding-right: 5px;
  margin-left: -5px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.dropdown-menu .dropdown-item:hover::before {
  content: "-";
  font-weight: bold;
  color: #ffffff;
  padding-right: 10px;
  margin-left: 3px;
  opacity: 1;
}

.dropdown-menu a {
  font-size: 15px;
}

/* ====== 1.2 - NAV BAR ENDS ====== */

/* ====== 1.3 - CAROUSEL STARTS ====== */
.hero-image {
  background: url(../public/images/home_hero_bg_2.png) no-repeat top center;
  min-height: 100vh;
  /* height: auto; */
  background-size: cover;
  padding-top: 100px;
}
.hero-text h5 {
  color: #333333;
}
.hero-text {
  text-align: center;
  padding: 20px 0px 40px 0px;
}

.hero-text h1 {
  font-weight: 600;
  padding: 0;
}

/* ====== 1.3 - CAROUSEL ENDS ====== */

/* ====== 1.4 - FOOTER STARTS ====== */
footer {
  background: #0a0a0a url(../public/images/footer_bg.html) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  padding: 60px 0px 0px 0px;
}
footer p {
  padding: 0;
  color: #999999;
  letter-spacing: 0.5px;
  font-size: 15px;
}
.adres {
  color: #ffffff;
  padding-bottom: 10px;
}
footer li,
footer a {
  color: #999999;
  font-size: 15px;
  padding: 5px 0;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in-out;
}
footer a:hover {
  text-decoration: none !important;
  color: #26cd79;
  padding-left: 6px;
}
footer h4,
footer h5 {
  color: #ffffff;
  line-height: 1.7rem;
}
footer .copyright {
  margin-top: 60px;
  border-top: 1px solid #333333;
  padding: 20px 0px;
}

footer .copyright p {
  color: #666666;
}

.copyright-links ul {
  float: left;
}

.copyright-links ul li {
  display: inline;
  padding-right: 20px;
}
.copyright-links ul li a {
  color: #666666;
  transition: all 0.1s ease-in-out;
  padding: 0;
}
.copyright-links ul li a:hover {
  color: #cccccc;
  padding: 0;
}

.copyright-social ul {
  float: right;
}
.copyright-social ul li {
  width: 35px;
  display: inline-block;
  padding: 0px 0px 0px 10px;
}
.copyright-social ul li a {
  padding: 0;
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}
.copyright-social ul li a:hover {
  opacity: 1;
}

/* ====== 1.4 - FOOTER ENDS ====== */

/* ====== 2 - HOME PAGE STARTS ====== */

/* home page form */
.form-home {
  width: 100%;
  background: #1e5d87;
  padding: 30px;
  border-radius: 8px;
  color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.form-home .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  font-size: 14px;
}

.form-home .form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 20px 15px 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: transparent;
  background-clip: padding-box;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 0px;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-check-inline {
  position: relative;
  display: inline-block;
  padding: 0;
}

.form-head {
  padding: 0px 0px 10px 0px;
}

.form-head h6 {
  padding: 0;
}

.form-head p {
  font-size: 14px;
}

/* home page form */

/* Inner page form */
.plan-selected ul li {
  background-color: #1e5d87;
  border-radius: 20px;
  padding: 30px;
}
.plan-selected ul li h5,
.plan-selected ul li h5 span,
.plan-selected ul li p {
  color: #ffffff;
}
.plan-selected ul li h5 {
  font-weight: 600;
}

.plan-selected ul li h5 span {
  font-size: 70%;
  font-weight: 600;
}

.plan-selected .form-check-input {
  margin: 25px 0px 0px 0px;
  position: relative;
  float: right;
  padding: 20px;
}
ul {
  margin-left: 0px !important;
}
ul {
  margin-top: 0;
  margin-left: 1rem !important;
}
.plan ul li {
  border: 1px solid #d7d2c4;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}
.plan ul li:hover {
  background-color: #1e5d87;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.plan ul li h5 {
  font-weight: 600;
  color: #1e5d87;
  transition: all 0.3s ease-in-out;
}
.plan ul li:hover h5,
.plan ul li:hover h5 span,
.plan ul li:hover p {
  color: #ffffff;
}
.plan ul li p {
  font-size: 13px;
  color: #333333;
  transition: all 0.4s ease-in-out;
}

.plan ul li h5 span {
  font-size: 70%;
  font-weight: 600;
  color: #555555;
  transition: all 0.3s ease-in-out;
}
.plan .form-check-input {
  margin: 25px 0px 0px 0px;
  position: relative;
  float: right;
  padding: 20px;
}
.offers {
  margin-bottom: 50px;
  /* padding: 30px 30px 0px 30px;
    border-radius: 20px;
    border: 1px solid #999999; */
}
.heading {
  font-weight: 500;
  padding-bottom: 30px;
}
.offer-text {
  margin: 0px 0px 0px 40px;
}

.offers ul li {
  position: relative;
  border-bottom: 1px solid #cccccc;
  padding: 0px 0px 20px 0px;
  margin: 0px 0px 20px 0px;
}

.offers ul li h6 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding: 0;
}
.offers ul li p {
  font-size: 14px;
  color: #666666;
}

.offers .list-icon {
  float: left;
}
.offers a {
  font-size: 14px;
  color: #302c2c;
  text-decoration: underline;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
}

.offers .list-icon span::before {
  font-size: 30px;
  font-weight: 500;
}
.car-info {
  padding-left: 30px;
  border-left: 2px solid #1e5d87;
}
.car-info li {
  padding: 10px 0px;
}
.send-quotes {
  margin-top: 50px;
  padding: 30px;
  border-radius: 20px;
}
.quote-text {
  padding-top: 5px;
  margin-top: 10px;
  border-top: 1px solid #999999;
}
.quote-text ul li {
  width: 30%;
  text-align: center;
  display: inline-block;
}
.quote-text p {
  font-size: 14px;
  padding: 0;
  line-height: normal;
}
.quote-text span::before {
  font-size: 30px;
  padding: 15px;
  margin: 0px 0px 0px 0px;
}

.insure-text {
  background-color: #a52b46;
  border-radius: 20px;
  padding: 5px 5px 5px 30px;
  margin-bottom: 30px;
}
.insure-text h4,
.insure-text h6,
.insure-text p {
  color: #ffffff;
}

.insure-form h5 {
  padding-bottom: 20px;
}
.insure-form h6 {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.insure-form .form-group label {
  display: inline;
  padding-right: 10px;
}

.insure-form .form-group {
  margin-bottom: 25px;
}

.insure-form .form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 0px 15px 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #666666;
  background-color: transparent;
  background-clip: padding-box;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-left: 0px;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.insure-form .form-control::placeholder {
  color: #666666;
  opacity: 1;
  font-size: 15px;
}
/* Inner page form */

.numbers {
  padding: 30px;
  background-color: #f2efe1;
  background-size: cover;
  border-radius: 30px;
  box-shadow: 0px;
  position: relative;
  transition: all 0.4s ease-in-out;
  margin-bottom: 15px;
}
.numbers .number-icons {
  max-width: 60px;
  height: auto;
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.1;
  transition: all 0.4s ease-in-out;
}
.numbers:hover .number-icons {
  max-width: 50px;
  top: 15px;
  right: 30px;
  opacity: 0.5;
}
.numbers:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.numbers h2 {
  color: #1e5d87;
  font-weight: 600;
}

.numbers h2 span {
  color: #222222;
  font-weight: 400;
  font-size: 18px;
  display: block;
}

.point-list {
  padding: 0px 20px 0px 0px;
}

.point-list .list-icon {
  float: left;
}

.list-icon span::before {
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  display: inline;
  margin: 0;
  background: -webkit-linear-gradient(#0ae376, #1e5d87);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.point-list .list-text {
  margin-left: 100px;
}

.point-list .list-text h6 {
  color: #333333;
  font-weight: 500;
}

.point-list ul li {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d7d2c4;
}

.point-list ul li:nth-last-child(1) {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

div .col-icon-bg-cntr {
  margin-bottom: 30px;
}
div .col-icon-bg-cntr:not(.no-margin) {
  margin-bottom: 30px;
}

/* feedback section */
.feedback {
  position: relative;
}
.feedback p {
  position: relative;
  top: 20px;
}
.feedback span,
.feedback h2 {
  position: relative;
  z-index: 100;
}
.feedback > img {
  position: absolute;
  top: -25%;
}
.feedback-list {
  background: url(../public/images/feedback_bg_2.png) center center no-repeat;
  background-size: contain;
  padding: 30px 30px 0px 30px;
  box-sizing: border-box;
}
.feedback-content-left {
  padding: 70px 0px;
  position: relative;
}

.item-1 {
  padding: 0px 3px 5px 3px;
}
.feedback-pic-left {
  width: 100px;
  height: 100px;
  border: 5px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  left: 30px;
  top: -50px;
}
.feedback-text-left {
  padding: 0px 30px 30px 30px;
  position: relative;
  top: -20px;
}

.feedback-text-left h6 span {
  font-size: 15px;
  color: #666666;
  display: block;
}

/* feedback section */

/* client section */
.client img {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.client img:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

/* client section */

/* ====== 2 - HOME PAGE ENDS ====== */

/* ====== 3 - ABOUT PAGE STARTS ====== */
.hero-small-about {
  background: url(../public/images/small_hero_bg-01.png) no-repeat bottom center;
  background-size: 100%;
}
.product {
  text-align: center;
  margin-bottom: 30px;
}
.pro-border::after {
  content: "";
  width: 1px;
  height: calc(100% - 30px);
  background-color: #cccccc;
  position: absolute;
  right: 0;
  top: 0;
}

.pro-border:nth-last-child(1)::after {
  content: "";
  width: 0px;
  height: calc(100% - 30px);
  position: absolute;
  right: 0;
  top: 0;
}

.product h6 {
  font-size: 18px;
  font-weight: 600;
  padding: 0;
}
.product .pro-icons {
  padding-bottom: 20px;
  transition: all 0.5s cubic-bezier(0.95, 0.5, 0.58, 5);
}

.product:hover .pro-icons {
  transform: translateY(-15px);
}

.pro-icons span::before {
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  display: inline;
  margin: 0;
  background: -webkit-linear-gradient(#ffac37, #e22c65);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
}

.team {
  text-align: center;
  height: auto;
  padding: 15px;
  border: 1px solid #ffffff;
  box-shadow: 0px;
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  margin-bottom: 6px;
  background: #fff;
}

.team:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.team img {
  transition: all 0.25s ease-in-out;
}
.team:hover img {
  transform: scale(1.1) translateY(5px);
  filter: grayscale(5);
}

.team-info {
  background-color: #ffffff;
  width: 100%;
  text-align: left;
  padding: 20px;
  position: absolute;
  bottom: 0px;
  left: 0;
  color: #222222;
}

.team-info h5 {
  color: #222222 !important;
  padding-bottom: 0;
}

.team-info p {
  color: #1e5d87 !important;
  font-size: 14px !important;
}

.full-section-team {
  background-color: #d7d2c4;
  padding: 120px 15px;
  margin: -15px;
}

.full-section-team .title-center p,
.full-section-team .title-center h2 {
  color: #ffffff;
}

/* ====== 3 - ABOUT PAGE ENDS ====== */

/* ====== 3 - CAR PAGE STARTS ====== */
.hero-image-small {
  width: 100%;
  height: 400px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text-inner {
  width: 100%;
  padding: 0px;
  margin-top: -80px;
}

.hero-text-inner h3 {
  font-weight: 600;
  padding: 0;
}

.hero-text-inner .sub-heading-1 {
  font-size: 20px;
}
.hero-small-car {
  background: url(../public/images/small_hero_bg-02.png) no-repeat bottom center;
  background-size: 100%;
}

.img-container-step {
  text-align: center;
}
.img-container-step img {
  width: auto;
  max-height: 170px;
}
.img-container-sml img {
  width: auto;
  max-height: 120px;
}
.steps {
  margin-bottom: 60px;
}

.step-head {
  padding: 0;
  margin-top: 30px;
}
.step-head h6 {
  color: #333333;
  padding: 0px 0px 0px 20px;
  font-weight: 500;
  line-height: 30px;
}
.step-text {
  width: 100%;
  float: left;
  padding-top: 20px;
}

.step-count {
  min-width: 70px;
  height: 60px;
  padding: 0px 10px;
  background-color: #d7d2c4;
  text-align: center;
  overflow: hidden;
}
.step-count h1 {
  font-size: 80px;
  font-weight: bold;
  color: #ece9d9;
}

.package {
  background: #d7d2c4 url(../public/images/package_bg.png) no-repeat top center;
  background-size: 100%;
  padding: 30px;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}
.package:hover {
  background: #dfdace url(../public/images/package_bg.png) no-repeat top center;
  background-size: 110%;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.3);
}
.package h5 {
  font-weight: 600;
  padding-bottom: 90px;
  text-align: center;
}
.package ul {
  margin-top: 10px;
}

.package ul li {
  font-size: 16px;
  padding: 20px 0px;
  border-bottom: 1px solid#b7b2a6;
  position: relative;
}

.package ul li.active {
  color: #111111;
}

.package ul li.active::after {
  content: "";
  background: url(../public/images/icon_ticked.png) no-repeat center left;
  background-size: contain;
}

.package ul li::after {
  content: "";
  display: block;
  background: url(../public/images/icon_tick.png) no-repeat center left;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 20px;
}

.package ul li:hover {
  color: #111111;
}
.duration h4 {
  font-size: 56px;
  line-height: 30px;
  font-weight: bold;
  color: #1e5d87;
  text-align: center;
}

.duration h4 span {
  font-size: 16px;
  font-weight: normal;
  color: #666666;
}

.package ul li:hover::after {
  background: url(../public/images/icon_ticked.png) no-repeat center left;
  background-size: contain;
}

.package ul li:last-child {
  border-bottom: 0px;
}
.package .btn {
  border-radius: 40px;
  width: 100%;
  background-color: #1e5d87;
  letter-spacing: 1px;
}

.package .btn:hover {
  background-color: #111111;
}

/* ====== 3 - CAR PAGE ENDS ====== */

/* ====== 4 - CAR CLAIM PAGE STARTS ====== */

.col-icon-cntr-claim {
  width: 100%;
  background-color: #1e5d87;
  padding: 50px 30px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  margin-bottom: 6px;
}

.col-icon-cntr-claim .circle-1 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: tomato;
  opacity: 0.3;
  position: absolute;
  top: 100px;
  left: 0px;
}
.col-icon-cntr-claim .circle-2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #2c1a26;
  opacity: 0.1;
  position: absolute;
  top: 0px;
  right: 0px;
}

.icon-cntr-claim span::before {
  font-size: 70px;
  font-weight: 500;
  line-height: normal;
  display: inline;
  margin: 0;
  color: #ffac37;
  transition: all 0.4s ease-in-out;
}
.col-icon-cntr-claim:hover .icon-cntr-claim span::before {
  color: #1e5d87;
}
.col-text-cntr-claim h6 {
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}
.col-icon-cntr-claim:hover .col-text-cntr-claim h6 {
  color: #333333;
}
.col-text-cntr-claim {
  text-align: center;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.col-icon-cntr-claim:hover {
  background-color: #f2efe1;
  padding: 50px 30px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}
.col-icon-cntr-claim .icon-cntr-claim {
  padding-bottom: 30px;
}
.icon-cntr-claim {
  text-align: center;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
}

.high-img {
  position: relative;
}

.high-img img {
  display: block;
  position: absolute;
  max-width: 100%;
}
.highlight h5,
.highlight-top h5 {
  line-height: 40px;
}

.col-img-cntr {
  margin-top: 0px;
  margin-bottom: 0px;
}
.img-cntr-claim img {
  max-width: 70%;
  height: auto;
  margin: 0px auto 30px auto;
  display: block;
}
.col-text-claim {
  text-align: center;
}

.col-text-claim h6 {
  font-weight: 600;
}

/* Insurance section */
.new-veh-img {
  max-width: 100px;
  height: auto;
  float: left;
  display: block;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.new-veh-text {
  display: inline-block;
}
.new-car h6 {
  padding: 0;
}
.new-car a {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  margin-top: 10px;
  background-color: #1e5d87;
  padding: 3px 12px;
  border-radius: 4px;
}
.new-car {
  background-color: #e5e0cf;
  padding: 15px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.user .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.user .dropdown-menu {
  background-color: #fffdf2;
  margin-top: 7px;
  border: none;
  border-top: 3px solid #1e5d87;
  padding: 0px;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}
.policy-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}

.m-policy .new-veh-img {
  max-width: 50px;
  height: auto;
  display: block;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.policy-section .m-policy {
  background-color: #f2efe1;
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.m-policy ul {
  display: flex;
}
.m-policy ul li {
  margin-right: 30px;
}
.m-policy ul li a {
  font-weight: 600;
  font-size: 15px;
}
.m-policy .product {
  margin-bottom: 0;
  text-align: center;
}
.m-policy .pro-pb {
  margin-bottom: 30px;
}
.m-policy .pro-pb:nth-last-child(1),
.m-policy .pro-pb:nth-last-child(2) {
  margin-bottom: 0px;
}
.m-policy .app-icon {
  max-width: 100px;
}

/* Insurance section */

/* ====== 4 - CAR CLAIM PAGE ENDS ====== */

/* ====== 5 - BIKE PAGE STARTS ====== */
.hero-small-bike {
  background: url(../public/images/small_hero_bg-03.png) no-repeat bottom center;
  background-size: 100%;
}

.bike-works span {
  font-size: 120px;
  font-weight: bolder;
  color: #d7d2c4;
  padding: 0;
  line-height: 0px;
  position: relative;
  top: 50px;
  z-index: -1;
}

.works-text h6 {
  font-weight: 500;
  color: #333333;
  line-height: normal;
}

.reason-sec {
  border-radius: 20px;
  padding: 60px 60px 30px 60px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: -130px;
  z-index: 1;
}

.reason-sec-back {
  height: 360px;
  background: url(../public/images/circal_bg.png) center 70% no-repeat;
  background-size: 95%;
  background-attachment: fixed;
}

.col-icon-left-sml {
  padding-bottom: 30px;
}
.icon-left-sml {
  float: left;
}
.text-left-sml {
  margin-left: 60px;
}

.text-left-sml p,
.text-left-sml h6 {
  color: #333333;
}
.text-left-sml h6 {
  font-weight: 500;
}

/* ====== 5 - BIKE PAGE ENDS ====== */
/* ====== 6 - BIKE CLAIAM PAGE STARTS ====== */

.col-icon-left {
  width: 100%;
  background-color: #fffdf2;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.col-icon-left h6 {
  font-weight: 500;
}
.col-icon-left:hover {
  background-color: #ffffff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
}
.icon-left {
  text-align: left;
  padding-bottom: 40px;
}
.icon-left span::before {
  font-size: 48px;
  font-weight: 500;
  line-height: normal;
  display: inline;
  margin: 0;
  background: -webkit-linear-gradient(#ffac37, #e22c65);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
}

.bike-claim .col-img-cntr {
  margin-top: 0px;
  margin-bottom: 30px;
}

div.bike-claim .col-img-cntr:nth-child(3) {
  margin-bottom: 0px;
}
div.bike-claim .col-img-cntr:nth-child(4) {
  margin-bottom: 0px;
}

/* ====== 6 - BIKE CLAIAM PAGE ENDS ====== */

/* ====== 7 - CONTACT US PAGE STARTS ====== */
.hero-small-contact {
  background: url(../public/images/small_hero_bg-04.png) no-repeat bottom center;
  background-size: 100%;
}
.contact {
  padding-bottom: 60px;
}
.contact img {
  max-width: 38px;
  height: auto;
  margin-bottom: 15px;
}
.contact span.lable {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333333;
  display: block;
  padding-bottom: 5px;
}
.map-container {
  overflow: hidden;
  position: relative;
  min-height: 500px;
  margin: 0px -15px;
}
.map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Custom contact form design */
.form-group {
  margin-bottom: 1rem;
}
label {
  font-size: 15px;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control::placeholder {
  color: #999999;
  opacity: 1;
  font-size: 14px;
}
.form-group textarea.msg-area {
  border-radius: 10px;
}
.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111111;
  background-color: #f2efe1;
  background-clip: padding-box;
  border: 1px solid #bfbdb0;
  border-radius: 50px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group .form-control {
  /* border-radius: 50px !important; */
}

.input-group-append {
  margin: 0px;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 100;
}
/* ====== 7 - CONTACT US PAGE ENDS ====== */

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */

/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #26cd79;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.css.map */
.navbar-light .navbar-nav .nav-link {
  font-size: 15px;
  color: #222222;
  transition: all 0.3s ease-in-out;
  padding: 20px 16px !important;
  padding-right: 27px;
  padding-left: 27px;
  font-weight: 600;
}
.form-control {
  display: block !important;
  width: 100% !important;
  height: auto !important;
  padding: 15px 0px 15px 15px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #fff !important;
  background-color: #1e5d87 !important;
  background-clip: padding-box !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-left: 0px !important;
  border-radius: 8px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
  -moz-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -ms-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.btn-prim-round {
  background-color: #fff !important;
  border: 0px !important;
  border-radius: 8px !important;
  padding: 10px !important;
  display: inline-block !important;
  line-height: 1rem !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  color: #1e5d87 !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
.form-check-label {
  margin-left: 10px;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
  border-bottom: 1px solid #333 !important;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
}
@media (max-width: 760px) {
  .bg-color-10 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 320px) and (max-width: 575.98px) {
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: 0px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    top: 4px;
  }
  .hero-text h1 {
    font-weight: 600;
    padding: 0;
    font-size: 33px;
  }
  .section-larger {
    padding: 40px 0px;
  }
  .section {
    padding: 40px 0px;
  }
  .feedback-content-left {
    padding: 70px 0px 0px;
    position: relative;
  }
  .section-first {
    padding: 50px 0px 30px 0px;
  }
  .section-large {
    padding: 50px 0px;
  }
}
@media (min-width: 320px) and (max-width: 575.98px) {
  .navbar-brand img {
    width: auto;
    max-height: 25px;
    padding: 0px 0px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar {
    position: relative;
    left: -136px;
    margin-top: 34px;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 8px 16px !important;
  }
  .user {
    margin-left: 31px;
  }
}

.overlay {
  background: #2125293b;
  width: 100%;
  position: absolute;
  height: 100%;
}
